import MainLayout from "Screens/components/MainLayout";
import MainPara from "Screens/components/MainPara";
import WhitePaperIcon from "../../Utils/Assets/Images/Icons/whitepaper.png";
import WhitePaperDrawer from "./components/WhitePaperDrawer";
export default function WhitepaperIndex() {
    return (
        <MainLayout
            flat
            pageContent={
                <>
                    <MainPara title={'whitePaperTitle'} charType align={'text-left'}/>
                    <img className="wp-image-back" src={WhitePaperIcon} alt={'whitepaper-icon'} />
                    <div className="info-page">
                        <WhitePaperDrawer />
                    </div>
                </>
            }
        />
    );
}