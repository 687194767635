import React from 'react';
import PagesShow from './Pages';
import CharShow from './Characters';
export default function MainLayout({
    pageContent,
    imageSrc,
    imageAlt,
    videoSrc,
    centered,
    flat,
}) {
    return (
        <>
            {
                !flat ? <PagesShow
                    imageSrc={imageSrc}
                    imageAlt={imageAlt}
                    leftChildren={pageContent}
                    videoSrc={videoSrc}
                    centered={centered}
                /> : <CharShow
                    pageContent={pageContent}
                    flat={flat}
                />
            }
        </>
    );
}