import IButtonSpc from "./iButtonSpc";
import * as Icons from 'react-bootstrap-icons';
import Roadmap from '../../Utils/Assets/Images/Icons/roadmap.png';
import Whitepaper from '../../Utils/Assets/Images/Icons/whitepaper.png';

export default function HomeButtons() {
    return (
        <div className='home-button'>
            <IButtonSpc
                label={'Roadmap'}
                icon={<img src={Roadmap} alt={'Roadmap-icon'}/>}
                link={'/roadmap'}
            />
            <IButtonSpc
                label={'Whitepaper'}
                icon={<img src={Whitepaper} alt={'Whitepaper-icon'}/>}
                link={'/whitepaper'}
            />
        </div>
    );
}