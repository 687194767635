import { useTranslation } from "react-i18next";
export default function FutureRoadmap() {
    const { t } = useTranslation();
    return (
        <div className="future-roadmap">
            <div className="future-roadmap-back-image"/>
            <div className="future-roadmap-back-color"/>
            <p>{t('unknowPhase')}</p>
        </div>
    );
}