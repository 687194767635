import { setOpenDialog } from "../../../Redux/slices/settingsSlice";
import ImageBox from "Screens/components/ImageBox";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
export default function SubCharShow({ character }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onClick = () => {
        dispatch(setOpenDialog({
            title: character.code,
            image: character.image,
            content: t(character.more)
        }));
    }
    return (
        <div className='sub-character-image' onClick={onClick}>
            <p className='image-id'>Code: {character.code}</p>
            <ImageBox image={character.image} alt={character.code} />
        </div>
    );
}