import * as Icons from 'react-bootstrap-icons';
import OpenSee from '../Assets/Images/opensee.svg'
import Rari from '../Assets/Images/Rari.svg'

export const Social = [
    {
        id: 1,
        name: 'TwitterX',
        icon: <Icons.TwitterX />,
        link: 'https://x.com/the_tamima'
    },{
        id: 2,
        name: 'EMail',
        icon: <Icons.EnvelopePaper />,
        link: 'info@tamima.io'
    // },{
    //     id: 3,
    //     name: 'OpenSee',
    //     icon: <img src={OpenSee}/>,
    //     link: 'https://opensea.io/TheTamima'
    },{
        id: 4,
        name: 'Rarible',
        icon: <img src={Rari}/>,
        link: 'https://rarible.com/tamima',
    },{
        id: 5,
        name: 'Instagram',
        icon: <Icons.Instagram />,
        link: 'https://www.instagram.com/thetamima?igsh=MW9idDZqZXFsODhsNA%3D%3D&utm_source=qr',
    },{
        id: 6,
        name: 'Discord',
        icon: <Icons.Discord />,
        link: 'https://discord.gg/GnSBMRTV'
    },
]