
//! status: 1 -> done | 2 -> proccess | 3 -> stop | 4 -> unknow

export const Roadmap = [
    {
        id: 1,
        phase: 1,
        title: 'rmp_1_title',
        levels: [
            {
                id: 101,
                step: '1.1',
                status: 1,
                value: 100,
                description: 'rmp_101',
            },{
                id: 102,
                step: '1.2',
                status: 1,
                value: 100,
                description: 'rmp_102',
            },{
                id: 103,
                step: '1.3',
                status: 1,
                value: 100,
                description: 'rmp_103',
            }
        ],
    },{
        id: 2,
        phase: 2,
        title: 'rmp_2_title',
        levels: [
            {
                id: 201,
                step: '2.1',
                status: 1,
                value: 100,
                description: 'rmp_201',
            },{
                id: 202,
                step: '2.2',
                status: 1,
                value: 100,
                description: 'rmp_202',
            }
        ],
    },{
        id: 3,
        phase: 3,
        title: 'rmp_3_title',
        levels: [
            {
                id: 301,
                step: '3.1',
                status: 2,
                value: 45,
                description: 'rmp_301',
            },{
                id: 302,
                step: '3.2',
                status: 3,
                value: 0,
                description: 'rmp_302',
            }
        ],
    },{
        id: 4,
        phase: 4,
        title: 'rmp_4_title',
        levels: [
            {
                id: 401,
                step: '4.1',
                status: 2,
                value: 30,
                description: 'rmp_401',
            },{
                id: 402,
                step: '4.2',
                status: 3,
                value: 0,
                description: 'rmp_402',
            },
            // {
            //     id: 403,
            //     step: '4.3',
            //     status: 4,
            //     value: 0,
            //     description: 'unknowPhase',
            // },
        ],
    },
]