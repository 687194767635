import React from 'react';
import MainLayout from 'Screens/components/MainLayout';
import MainPara from 'Screens/components/MainPara';
// -- video
import Tamima from "Utils/Assets/Images/tamima.webm";
// -- video
export default function TamimaIndex() {
    return (
        <MainLayout
            pageContent={
                <>
                    <MainPara title={'tamimaPageTitle'} text={'tamimaPageText'} />
                    <MainPara title={'tamimaSecondParaTitle'} text={'tamimaSecondParaText'} />
                    <MainPara title={'tamimaThirdParaTitle'} text={'tamimaThirdParaText'} />
                </>
            }
            videoSrc={Tamima}
        />
    );
}