import { useNavigate } from 'react-router-dom';
import { MenuItems } from 'Utils/Constants/MenuItems';
import { move2Top } from 'Utils/Helpers/ApiServices';
import * as Icons from 'react-bootstrap-icons';
import { useState } from 'react';

export default function MenuDrawer({ onClick }) {
    const navigate = useNavigate();
    const [lg, setLg] = useState(window.innerWidth > 992 ? false : true);
    const [subMenuItems, setSubMenuItems] = useState([]);
    window.onresize = (e) => { setLg(window.innerWidth > 992 ? false : true); }
    const handlePush = (item) => {
        setSubMenuItems([]);
        navigate(item.action);
        move2Top();
        try{ onClick() } catch {}
    }
    return (
        <>
            <ul key={'ulMain'}>
                {
                    MenuItems.filter(item => item.active).map(item => {
                        return (
                            <>
                                <li
                                    key={`menuItems-${item.id}`}
                                    onClick={() => handlePush(item)}
                                    className={
                                        window.location.pathname === item.action ? 'active' :
                                        !lg ? (window.location.pathname === '/roadmap' || window.location.pathname === '/whitepaper')
                                        && item.action === '/' ? 'active' : '' : ''
                                    }
                                    onMouseEnter={() => setSubMenuItems([])}
                                >
                                    <p
                                        key={`menuItemsP-${item.id}`}
                                        className='text-nowrap'
                                    >
                                        {item.title}
                                    </p>
                                    {
                                        item.subMenu.length !== 0 && 
                                        <div
                                            className='menu-items-more-sign'
                                            onMouseEnter={() => setSubMenuItems(item.subMenu)}
                                        >
                                            <Icons.ChevronDown size={11}/>
                                        </div>
                                    }
                                </li>
                                {
                                    item.subMenu.length !== 0 && 
                                    item.subMenu.filter(i => i.active).map(i => {
                                        return (
                                            <li
                                                key={`menuItems-${item.id}-${i.id}`}
                                                onClick={() => handlePush(i)}
                                                className={`menu-items-more-mobile ${window.location.pathname === i.action ? 'active' : ''}`}
                                            >
                                                <p
                                                    key={`menuItemsP-${item.id}-${i.id}`}
                                                    className='text-nowrap'
                                                >
                                                    {i.title}
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </>
                        )
                    })
                }
            </ul>
            {
                subMenuItems.length !== 0 &&
                <div
                    key={`subMenuItemMain`}
                    className="menu-items-more"
                    onMouseLeave={() => setSubMenuItems([])}
                >
                    {
                        subMenuItems.filter(i => i.active).map(i => {
                            return <div
                                key={`subMenuItem-${i.id}`}
                                onClick={() => handlePush(i)}
                                className={`sub-menu-item ${window.location.pathname === i.action ? 'active' : ''}`}
                            >
                                <p
                                    key={`subMenuItem-${i.id}`}
                                    className='text-nowrap'
                                >
                                    {i.title}
                                </p>
                            </div>
                        })
                    }
                </div>
            }
        </>
    );
}