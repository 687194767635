export const frTexts = {
    welcome: "Les [Émanations] de Mon Esprit",
    welcomeText: "Bienvenue dans un monde où l'imagination prend forme et la créativité devient réalité. Chaque pièce que vous voyez ici commence comme une étincelle dans mon esprit, évoluant en personnages extraordinaires. Lorsque je peins ces mythes, j'ai l'impression qu'ils naissent dans une autre dimension.@BRExplorez la page Tamima pour découvrir mon processus artistique en direct, alors que je transforme ces pensées en œuvres dessinées à la main, sous vos yeux. Immergez-vous dans cette expérience et connectez-vous à l'essence de l'art comme jamais auparavant.",
    homeSecondParaTitle: "Qu’est-ce qu’un [NFT] ?@BRComment en [posséder] un ?",
    homeSecondParaText: "Les NFTs, ou Tokens Non Fongibles, sont des objets de collection numériques qui utilisent la technologie blockchain pour garantir que chaque pièce est unique et ne peut être reproduite. En transformant mes œuvres dessinées à la main en NFTs, je vous offre la possibilité de posséder des pièces numériques exclusives, vérifiables et originales. Ces actifs numériques vous permettent de soutenir l’art tout en possédant une part de l’histoire numérique!",
    homeThirdParaTitle: 'Comment [acheter] ?',
    homeThirdParaText: 'Pour posséder l’un de mes NFTs, vous aurez besoin d’un portefeuille de cryptomonnaies et d’un peu d’Ethereum (ETH). Voici un guide rapide pour commencer:@BR[1. Créez votre portefeuille:] Téléchargez une application de portefeuille comme MetaMask ou Trust Wallet, puis suivez les étapes pour créer votre portefeuille.@BR[2. Achetez de l’Ethereum (ETH):] Ajoutez de l’Ethereum à votre portefeuille via un échange comme Coinbase ou Binance.@BR[3. Connectez votre portefeuille:] Rendez-vous sur des plateformes NFT (comme OpenSea) et connectez votre portefeuille pour commencer à explorer et acheter des NFTs.',
    homeFourthParaTitle: "Explorez la [collection]@BR{et}@BRRejoignez la [révolution de l’art numérique] !",

    charactersPageTitle: 'Mes [Personnages]',
    charactersPageText: "Tous ces personnages et êtres proviennent de mon âme, de mon subconscient. Et celui qui t'a attiré, son histoire vient de ton âme et de ton subconscient, pour toi et rien que pour toi. Il n'y a pas de limite aux histoires. Celui qui se connecte émotionnellement avec toi et touche ton âme, tu peux trouver son histoire dans les profondeurs de ton être, là où se trouve l'origine de l'univers.@BRFais attention, j'ai dit que tu peux trouver l'histoire de n'importe quel personnage qui t'a attiré, car il est proche de ton âme.@BRJe serais heureux si tu partageais ton histoire avec moi.",
    charactersCodeShow: 'Code des caractères [@P1]',
    charactersCodeText: 'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression.',
    otherShapeOfCharacter: "d'autres [personnages]",
    character_1_description: "La reine est un être d'une beauté unique, doté d'yeux extraordinaires qui voient au-delà de l'ordinaire. Son regard est rempli d'amour, mais lorsque la justice l'exige, ses yeux deviennent enflammés, rétablissant l'équilibre d'un simple coup d'œil. Ses cils apportent le parfum du printemps et de la vitalité, une essence divine partout où elle se trouve. Ce parfum céleste est différent de tout ce que l'on a jamais expérimenté. Ses oreilles peuvent entendre même les murmures les plus faibles de la galaxie, les traitant à travers six couches avant qu'elle ne décide de parler. Son esprit transcende le temps, comprenant la nécessité d'une vie pleine de sens. Son corps, fait de soie divine pure, porte des pouvoirs qu'elle utilise avec sagesse et grâce.",
    character_2_description: "En raison de ses capacités très élevées, il est chargé de la communication avec les forces créatives des autres planètes.@BRAvec sa bouche et les symboles sur son visage, il établit des connexions avec d'autres planètes. Grâce à ses oreilles, il écoute leurs messages et évalue si ces autres planètes ont la capacité de communiquer avec la planète Tamima. Si c'est le cas, une connexion avec Tamima est établie, utilisant leur créativité au profit de Tamima.@BRDe plus, grâce à ses symboles remarquables et à l'énergie élevée présente dans son corps, il peut presque contrôler la rotation de la planète Tamima.",
    character_3_description: "Légendes Bleues",
    character_4_description: "New Collection",

    tamimaPageTitle: "Le Début d'un Voyage [Mystique]",
    tamimaPageText: "Tout a commencé le jour où je méditais pour apaiser mon esprit et mon corps... J'avais les yeux fermés et je suis tombé dans un rêve merveilleux. Dans ce rêve, j'ai vu une planète peuplée de créatures extraordinaires, chacune me regardant avec des yeux profonds et significatifs, portant un désir non exprimé. Je sentais que mon âme, grâce à la tranquillité que j'avais trouvée, était entrée dans leur monde. Les voir a enflammé en moi une passion ardente pour les représenter à travers mon art. Et j'ai commencé à dessiner...",
    tamimaSecondParaTitle: "Guidé par des Forces [Invisibles]",
    tamimaSecondParaText: "Avec la première ligne tracée sur le papier, mon esprit conscient semblait s'éteindre et mon subconscient a pris le relais. Le temps s'est arrêté ; je n'entendais plus les sons autour de moi et tous mes sens étaient immergés dans un autre monde. En dessinant ces créatures extraordinaires, j'entendais leurs voix dans mon esprit, chacune parlant avec un ton amical et mystérieux, m'exhortant à les amener dans notre monde. Une force puissante s'était connectée à mon cerveau, guidant mes mains. En dessinant, la voix de chaque créature chuchotait dans mon esprit, me guidant sur la manière de capturer leur image de manière splendide. Ils guidaient chaque ligne et courbe, me disant exactement comment ma main devait bouger.",
    tamimaThirdParaTitle: "Création au-delà du [Temps] et de [l'Espace]",
    tamimaThirdParaText: "Le choix des couleurs est devenu une conversation intime ; ils me disaient quelles couleurs les représentaient le mieux. Certains dessins prenaient forme facilement en quelques minutes, tandis que d'autres prenaient des heures, chaque ligne et chaque couleur étant guidées par les voix. Pendant tout ce temps, je travaillais en silence, écoutant uniquement les instructions qui m'étaient données. Dans le processus de dessin, ils décrivaient leur apparence et me disaient quels détails ajouter, y compris les inscriptions sur leurs corps et les numéros utilisés en eux, tout cela à travers les voix dans mon esprit. Après avoir terminé chaque dessin, je ressentais une sensation de fatigue similaire à celle ressentie après des heures de pratique, fatigué mais enthousiaste, car beaucoup d'énergie avait été investie dans chaque œuvre. Quand je regarde mes œuvres, je me retrouve souvent dans la surprise de ne pas me souvenir de certains détails, car ces peintures proviennent entièrement de mon subconscient, sans l'influence de mon esprit conscient. Je suis profondément reconnaissant pour ce don unique – la capacité de représenter ces créatures et de les partager avec le monde. Je suis certain que ces créatures existent quelque part dans la galaxie et sont désireuses d'être vues. Peut-être que dans un avenir proche, nous pourrons voir ces créatures dans le monde réel.",
    
    contactusTitle: '[Contact] nous',
    contactusText: 'par les moyens suivants',
    emailTitle: '[Contact] direct',
    emailPlaceHolder: 'Tapez quelque chose ...',
    emailSend: 'Envoyer',
    successEmail: 'E-mail envoyé avec succès',
    successEmailThank: 'Merci pour votre participation',
    //! ------------------------ Characters
    sub_info_4466: "Une créature sans la capacité de parler communique par ses actions. Elle détecte les mensonges grâce aux points jaunes sur son corps et répond en utilisant des faisceaux rouges de son corps. Si elle est en colère, le faisceau devient plus rouge, tandis qu'en état calme, le faisceau s'adoucit.",
    sub_info_3333: "Cette créature a une marque circulaire sur la tête qui absorbe l'énergie négative de la planète et la neutralise.",
    sub_info_2222: "Avec un pouvoir spécial situé dans sa bouche, cet être empêche la croissance excessive des arbres sur la planète Tamima, les élaguant pour maintenir l'équilibre.",
    sub_info_1133: "Si des plantes indésirables tentent de pousser, cette créature utilise ses cils pour les dessécher, les transformant en sol semblable à celui de Tamima.",
    sub_info_3322: "Elle possède un sens de l'odorat remarquable, capable de détecter et de repousser les insectes nuisibles qui tentent d'envahir Tamima depuis d'autres planètes.",
    sub_info_8899: "Avec ses yeux et ses cils, elle fertilise et laboure les terres de Tamima, les préparant à faire pousser un type de plante spéciale qui protège la planète.",
    sub_info_7788: "Son visage, semblable à des oreilles, lui confère une audition excellente. Elle capte même les sons les plus faibles et les envoie à la reine pour analyse en utilisant ses cils. (L'une des capacités de la reine est de comprendre la véritable signification derrière les paroles.)",
    sub_info_6677: "Elle absorbe toute la lumière violette de Tamima, la catégorise à l'aide de ses cils, et distribue la lumière dans les zones nécessitant de l'énergie.",
    sub_info_5566: "Sa tête agit comme un radar, capable d'examiner des rayons ou des lumières étranges. Elle dirige ces rayons vers le côté gauche de la planète, neutralisant les menaces potentielles.",
    sub_info_111: "Son devoir est d'examiner les documents de Tamima, les estampillant pour approbation avec ses cils avant de les envoyer à la reine.",
    sub_info_4444: "Cet être est très conscient et réfléchi sur les questions. Il parle avec sagesse, faisant des pauses prudentes avant de répondre à ce qu'il entend. Il ne parle pas d'un sujet à moins de l'avoir vu lui-même.",
    sub_info_9999: "En raison des chiffres sur son visage, il est responsable de calculer le nombre de météorites et les distances entre les planètes.",
    sub_info_4455: "Avec ses oreilles et ses yeux rouges, il possède une vision et une audition accrues. Il peut détecter les minuscules insectes sous le sol de Tamima et utilise le symbole sur sa tête pour transformer ces insectes en créatures utiles et bénéfiques pour la planète.",
    sub_info_8888: "Cette créature se spécialise dans l'identification de tout étranger tentant d'entrer à Tamima. Elle a la capacité de reconnaître les individus qui ont changé d'apparence.",
    sub_info_7777: "En raison de la forme unique de sa tête et de son front, elle fonctionne comme un puissant appareil photo ou télescope, capable d'observer des distances bien au-delà de Tamima sur des kilomètres.",
    sub_info_3344: "Avec ses multiples yeux et sa grande oreille, elle possède d'excellentes capacités de lecture labiale et d'audition. Elle peut comprendre les langues de toutes les autres planètes en utilisant ses yeux, les analyser avec son oreille, et alerter la reine si un danger est détecté venant d'autres êtres.",
    sub_info_6666: "Il y a cinq points rouges sur son visage et son corps, qui émettent un signal d'alarme si quelqu'un tente de quitter Tamima sans autorisation. Ses cils changent rapidement de forme pour détecter la direction d'où la personne part et l'empêchent de quitter la planète.",
    sub_info_5555: "Sa tête peut se détacher de son corps. Pendant la séparation, à la fois sa tête et son corps restent vivants, lui permettant d'être présente en deux endroits en même temps si nécessaire.",
    sub_info_2233: "Avec les formes géométriques et mathématiques dans ses cils et son corps, elle a le pouvoir de changer certaines maisons sur Tamima qui nécessitent des modifications.",
    sub_info_333: "Avec son expression faciale bienveillante, elle peut transformer la tristesse en joie si elle détecte de la peine chez l'un des habitants de Tamima. Son visage bleu leur apporte la paix.",
    sub_info_1122: "La capacité de cette créature à créer un arc-en-ciel, due à ses yeux dépareillés et à une pièce inhabituelle à l'arrière de sa tête, est vraiment remarquable. En faisant tourner ses yeux, elle forme un arc-en-ciel avec trois couleurs : bleu, jaune et gris. Contrairement à l'arc-en-ciel terrestre, cet arc-en-ciel circulaire rehausse la beauté mystérieuse de Tamima.",
    //! ------------------------ Roadmap
    roadmapTitle: "Le Tamima [Roadmap]",
    phase: "Phase",
    unknowPhase: "La suite de la feuille de route est en cours de planification !",
    rmp_1_title: "Construction de la fondation",
    rmp_101: "Nous allons établir la présence numérique de notre collection sur des plateformes clés comme Opensea et les réseaux sociaux (Twitter, Instagram et Discord). En partageant régulièrement du contenu et des mises à jour, nous allons construire un engagement et un intérêt communautaire.",
    rmp_102: "Nous allons annoncer le lancement de notre site internet et promouvoir l'aspect unique et fait main de nos NFTs, en soulignant que chaque pièce est dessinée avec le cœur et l'âme.",
    rmp_103: "Nous allons organiser un événement de lancement en ligne pour introduire notre collection NFT, offrant des avant-premières et des aperçus des coulisses du processus créatif.",
    rmp_2_title: "Expansion de la communauté",
    rmp_201: "Nous allons créer du contenu quotidien ou hebdomadaire sur Twitter pour raconter les histoires de nos NFTs, leurs origines et le parcours créatif qui les a inspirés. Nous inviterons la communauté à s'engager, partager ses réflexions et discuter.",
    rmp_202: "Nous allons collaborer avec des influenceurs et des communautés NFT pour mettre en avant notre collection. En nous associant avec des figures clés, nous construirons une crédibilité et élargirons notre portée.",
    rmp_3_title: "Développement des utilités",
    rmp_301: "Nous introduirons des avantages exclusifs pour les collectionneurs, comme l'accès à des NFTs en édition limitée, du contenu exclusif et des droits de vote pour les développements futurs de notre projet.",
    rmp_302: "Nous développerons un programme d'adhésion où les collectionneurs recevront un accès anticipé à nos futures sorties, des cadeaux spéciaux, ou des expériences exclusives liées à notre art.",
    rmp_4_title: "Croissance à long terme et héritage",
    rmp_401: "Nous commencerons à nous engager avec les communautés NFT et lancerons des collaborations avec d'autres artistes et plateformes. En construisant des relations solides, nous favoriserons des connexions durables et une croissance à long terme.",
    rmp_402: "Nous développerons un plan d'expansion incluant des produits physiques ou des expériences basées sur nos personnages NFT, comblant ainsi l'écart entre l'art numérique et physique.",
    //! ------------------------ WhitePaper
    whitePaperTitle: "The Tamima NFT Collection [Livre blanc]",
    wp_1_title: "Introduction",
    wp_1_text: "The_Tamima est une collection unique et extraordinaire de NFT, fabriquée à la main, imprégnée de cœur, d'âme et d'imagination. Chaque pièce raconte une histoire issue des profondeurs de l'univers créatif de l'artiste, capturant la beauté du mystique et de l'extraordinaire. La collection vise à connecter les collectionneurs à un sens plus profond de l'art tout en créant de la valeur pour la communauté NFT. Avec pour mission d'atteindre les amateurs d'art, les collectionneurs et les passionnés de l'extraordinaire, The_Tamima est un mélange d'expression personnelle et d'artisanat de haute qualité.",
    wp_2_title: "Vision",
    wp_2_text: "Créer un impact durable dans le monde des NFT en mélangeant l'art fait main avec l'innovation numérique, en offrant une valeur à long terme et des expériences mémorables aux collectionneurs du monde entier. Grâce à la collection The_Tamima, nous visons à favoriser une communauté forte et solidaire de passionnés de NFT qui apprécient la beauté de l'art et la valeur de la narration.",
    wp_3_title: "Mission",
    wp_3_text: "Notre mission est de construire un monde où chaque collectionneur se sent connecté aux histoires uniques derrière l'œuvre d'art qu'il possède. Nous voulons inspirer les autres à travers notre art artisanal, tout en offrant des avantages tangibles à ceux qui choisissent de faire partie de ce voyage. En proposant différents types de NFT à différents prix, nous garantissons qu'il existe un point d'entrée pour tout le monde, des acheteurs novices aux collectionneurs chevronnés.",
    wp_4_title: "La collection",
    wp_4_text: "La collection NFT_Tamima se compose d'un nombre limité d'œuvres d'art dessinées à la main, chaque pièce étant méticuleusement conçue avec des détails qui reflètent l'imagination et la passion de l'artiste. La gamme de personnages et de créatures de cette collection témoigne de la créativité et de l'innovation, chacun possédant des pouvoirs, des capacités et des traditions uniques qui trouveront un écho auprès des collectionneurs.",
    wp_5_title: "Utilité et avantages",
    wp_5_text: "Nous proposons un système d'avantages à plusieurs niveaux en fonction du type de NFT acheté :@BR[1.] Niveau de base : accès à du contenu exclusif en coulisses, y compris des enregistrements vidéo du processus de création et un accès anticipé aux nouveaux NFT.@BR[2.] Niveau intermédiaire : accès anticipé aux collaborations, offres spéciales et une chance de gagner des commandes d'œuvres d'art personnalisées en tête-à-tête.@BR[3.] Niveau Premium : Les détenteurs de NFT de niveau premium auront droit à un canal direct avec l'artiste, permettant des expériences personnalisées, des invitations à des événements virtuels privés et des informations préliminaires sur les projets futurs.",
    wp_6_title: "Feuille de route",
    wp_6_text: "[Phase 1] : Construire les fondations@BR- Établir une forte présence sur des plateformes telles qu'OpenSea, les réseaux sociaux et un site Web dédié.@BR- Organiser un événement en ligne pour présenter officiellement la collection au public.@BR[Phase 2] : Élargir la communauté@BR- Développer l'engagement communautaire par le biais de récits et d'expériences interactives.@BR- Collaborer avec des influenceurs et des artistes pour présenter les NFT The_Tamima.@BR[Phase 3] : Développement des utilités@BR- Déploiement d'avantages exclusifs aux détenteurs de NFT, tels que du contenu personnalisé et l'accès aux futurs drops.@BR[Phase 4] : Croissance à long terme@BR- Développer des collaborations avec des artistes et des marques pour créer une valeur durable.@BR- Explorer les produits physiques et les expériences liées au monde numérique de The_Tamima.",
    wp_7_title: "Conclusion",
    wp_7_text: "La collection NFT de The_Tamima n’est pas seulement un autre actif numérique, mais un pont entre l’art traditionnel et la nouvelle frontière des NFT. Notre objectif est d’engager les collectionneurs avec des pièces uniques et artisanales qui offrent plus qu’une simple propriété – elles offrent une connexion, une histoire et une expérience. Nous vous invitons à faire partie de ce voyage, alors que nous continuons à repousser les limites de la créativité et de la communauté dans le monde des NFT.", 
}