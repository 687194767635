export const itTexts = {
    welcome: "Le [Emanazioni] della Mia Mente",
    welcomeText: "Benvenuti in un mondo dove l'immaginazione prende forma e la creatività diventa realtà. Ogni opera che vedete qui inizia come una scintilla nella mia mente, evolvendosi in personaggi straordinari. Quando dipingo questi miti, sento che stanno nascendo in un'altra dimensione.@BREsplorate la pagina Tamima per assistere al mio processo artistico in diretta, mentre trasformo questi pensieri in opere disegnate a mano proprio davanti ai vostri occhi. Immergetevi nell'esperienza e connettetevi con l'essenza dell'arte come mai prima d'ora.",
    homeSecondParaTitle: "Cos'è un [NFT] ?@BRCome puoi [possederne] uno ?",
    homeSecondParaText: "Gli NFT, o Token Non Fungibili, sono oggetti digitali da collezione che utilizzano la tecnologia blockchain per garantire che ogni pezzo sia unico e non riproducibile. Trasformando la mia arte disegnata a mano in NFT, ti offro la possibilità di possedere opere digitali esclusive e verificabili come originali. Questi asset digitali ti consentono di supportare l’arte possedendo anche una parte della storia digitale!",
    homeThirdParaTitle: 'Come [acquistare] ?',
    homeThirdParaText: 'Per possedere uno dei miei NFT, avrai bisogno di un portafoglio di criptovalute e di un po’ di Ethereum (ETH). Ecco una guida rapida per iniziare:@BR[1. Configura il tuo portafoglio:] Scarica un’app come MetaMask o Trust Wallet e segui i passaggi per creare il tuo portafoglio.@BR[2. Acquista Ethereum (ETH):] Aggiungi Ethereum al tuo portafoglio tramite un exchange come Coinbase o Binance.@BR[3. Collega il tuo portafoglio:] Vai su piattaforme di NFT (come OpenSea) e collega il tuo portafoglio per iniziare a esplorare e acquistare NFT.',
    homeFourthParaTitle: "Esplora la [collezione]@BR{e}@BRUnisciti alla [rivoluzione dell’arte digitale] !",

    charactersPageTitle: 'I miei [Personaggi]',
    charactersPageText: "Tutti questi personaggi e esseri provengono dalla mia anima, dal mio subconscio. E chiunque ti abbia attirato, la sua storia nasce dalla tua anima e dal tuo subconscio, per te e solo per te. Non c'è limite alle storie. Chiunque si connetta emotivamente con te e tocchi la tua anima, puoi trovare la sua storia nelle profondità del tuo essere, dove si trova l'origine dell'universo.@BRFai attenzione, ho detto che puoi trovare la storia di qualsiasi personaggio che ti abbia attratto, perché è vicino alla tua anima.@BRSarei felice se condividessi la tua storia con me.",
    charactersCodeShow: 'Codice dei personaggi [@P1]',
    charactersCodeText: 'Lorem Ipsum è semplicemente un testo fittizio utilizzato nel settore della tipografia e della stampa.',
    otherShapeOfCharacter: 'altri [personaggi]',
    character_1_description: "La regina è un essere di bellezza unica, con occhi straordinari che vedono oltre l'ordinario. Il suo sguardo è pieno d'amore, ma quando la giustizia lo richiede, i suoi occhi si infiammano, ristabilendo l'equilibrio con un semplice sguardo. Le sue ciglia portano il profumo della primavera e della vitalità, un'essenza divina ovunque si trovi. Questo aroma celestiale è diverso da qualsiasi cosa sia mai stata sperimentata. Le sue orecchie possono sentire anche i sussurri più deboli della galassia, elaborandoli attraverso sei strati prima che lei decida di parlare. La sua mente trascende il tempo, comprendendo la necessità di una vita significativa. Il suo corpo, fatto di pura seta divina, porta poteri che usa con saggezza e grazia.",
    character_2_description: "A causa delle sue elevate capacità, è responsabile della comunicazione con le forze creative di altri pianeti.@BRCon la sua bocca e i simboli sul viso, stabilisce connessioni con altri pianeti. Attraverso le sue orecchie, ascolta i loro messaggi e valuta se questi altri pianeti hanno la capacità di comunicare con il pianeta Tamima. Se lo fanno, si stabilisce una connessione con Tamima, utilizzando la loro creatività a beneficio di Tamima.@BRInoltre, grazie ai suoi simboli straordinari e all'alta energia presente nel suo corpo, è in grado di controllare quasi la rotazione del pianeta Tamima.",
    character_3_description: "Leggende Blu",
    character_4_description: "New Collection",

    tamimaPageTitle: "L’inizio di un Viaggio [Mistico]",
    tamimaPageText: "Tutto iniziò il giorno in cui meditavo per calmare la mia mente e il mio corpo… Avevo gli occhi chiusi e sono caduto in un sogno meraviglioso. In questo sogno, ho visto un pianeta pieno di creature straordinarie, tutte che mi guardavano con occhi profondi e significativi, portando un desiderio non espresso. Sentivo che la mia anima, grazie alla tranquillità che avevo trovato, era entrata nel loro mondo. Vederle ha acceso in me una passione ardente per rappresentarle attraverso la mia arte. E ho iniziato a disegnare…",
    tamimaSecondParaTitle: "Guidato da Forze [Invisibili]",
    tamimaSecondParaText: "Con la prima linea tracciata sulla carta, la mia mente cosciente sembrava spegnersi e il mio subconscio prese il controllo. Il tempo si fermò; non sentivo più i suoni intorno a me e tutti i miei sensi erano immersi in un altro mondo. Mentre disegnavo queste creature straordinarie, sentivo le loro voci nella mia mente, ognuna parlando con un tono amichevole e misterioso, esortandomi a portarli nel nostro mondo. Una forza potente si collegava al mio cervello, guidando le mie mani. Mentre disegnavo, la voce di ogni creatura sussurrava nella mia mente, guidandomi su come catturare la loro immagine in modo splendido. Guidavano ogni linea e curva, dicendomi esattamente come la mia mano doveva muoversi.",
    tamimaThirdParaTitle: "Creazione Oltre il [Tempo] e [lo Spazio]",
    tamimaThirdParaText: "La scelta dei colori diventava una conversazione intima; mi dicevano quali colori li rappresentavano meglio. Alcuni disegni prendevano forma facilmente in pochi minuti, mentre altri richiedevano ore, ogni linea e colore guidati dalle voci. Durante tutto questo tempo, lavoravo in silenzio, ascoltando solo le istruzioni che mi venivano date. Nel processo di disegno, descrivevano il loro aspetto e mi dicevano quali dettagli aggiungere, comprese le iscrizioni sui loro corpi e i numeri utilizzati in essi, tutto attraverso le voci nella mia mente. Dopo aver completato ogni disegno, provavo una sensazione di affaticamento simile a dopo ore di pratica, stanco ma entusiasta, poiché molta energia era stata messa in ogni lavoro. Quando guardo le mie opere, spesso mi trovo a meravigliarmi di alcuni dettagli che non ricordo, poiché questi dipinti provengono interamente dal mio subconscio, senza l’influenza della mia mente cosciente. Sono profondamente grato per questo dono unico – la capacità di rappresentare queste creature e condividerle con il mondo. Sono certo che queste creature esistono da qualche parte nella galassia e sono desiderose di essere viste. Forse nel prossimo futuro, potremo vedere queste creature nel mondo reale.",
    
    contactusTitle: '[Contattaci]',
    contactusText: 'attraverso i seguenti modi',
    emailTitle: '[Contatto] diretto',
    emailPlaceHolder: 'Digita qualcosa ...',
    emailSend: 'Invia',
    successEmail: 'Email inviata correttamente',
    successEmailThank: 'Grazie per la tua partecipazione',
    //! ------------------------ Characters
    sub_info_4466: "Una creatura senza la capacità di parlare comunica attraverso le sue azioni. Rileva le bugie attraverso i punti gialli sul suo corpo e risponde utilizzando raggi rossi dal suo corpo. Se è arrabbiata, il raggio diventa più rosso, mentre in stati calmi, il raggio si attenua.",
    sub_info_3333: "Questa creatura ha un segno circolare sulla testa che assorbe l'energia negativa dal pianeta e la neutralizza.",
    sub_info_2222: "Con un potere speciale situato nella bocca, questa creatura impedisce la crescita eccessiva degli alberi sul pianeta Tamima, potandoli per mantenere l'equilibrio.",
    sub_info_1133: "Se qualsiasi pianta indesiderata cerca di crescere, questa creatura usa le sue ciglia per seccarla, trasformandola in terreno simile a quello di Tamima.",
    sub_info_3322: "Possiede un senso dell'olfatto straordinario, in grado di rilevare e respingere gli insetti dannosi che tentano di invadere Tamima da altri pianeti.",
    sub_info_8899: "Con i suoi occhi e le ciglia, fertilizza e ara le terre di Tamima, preparandole per coltivare un tipo speciale di pianta che protegge il pianeta.",
    sub_info_7788: "Il suo volto, che ricorda delle orecchie, le conferisce un udito eccellente. Raccoglie anche i suoni più lievi e li invia alla regina per l'analisi utilizzando le sue ciglia. (Una delle abilità della regina è capire il vero significato dietro le parole.)",
    sub_info_6677: "Assorbe tutta la luce viola di Tamima, la categorizza usando le sue ciglia e distribuisce la luce nelle aree che hanno bisogno di energia.",
    sub_info_5566: "La sua testa funge da radar, capace di esaminare raggi o luci strane. Dirige questi raggi verso il lato sinistro del pianeta, neutralizzando potenziali minacce.",
    sub_info_111: "Il suo compito è esaminare i documenti di Tamima, timbrandoli per l'approvazione con le sue ciglia prima di inviarli alla regina.",
    sub_info_4444: "Questo essere è molto consapevole e riflessivo riguardo alle questioni. Parla con saggezza, facendo pause attente prima di rispondere a ciò che sente. Non parla di un argomento a meno che non l'abbia visto personalmente.",
    sub_info_9999: "A causa dei numeri sul suo viso, è responsabile del calcolo del numero di meteoriti e delle distanze tra i pianeti.",
    sub_info_4455: "Grazie al colore rosso delle sue orecchie e occhi, ha una visione e un udito molto acuti. Può rilevare piccoli insetti sotto il suolo di Tamima e usa il simbolo sulla sua testa per trasformare questi insetti in creature utili e benefiche per il pianeta.",
    sub_info_8888: "Questa creatura si specializza nel riconoscere qualsiasi straniero che tenta di entrare a Tamima. Ha la capacità di riconoscere individui che hanno cambiato il loro aspetto.",
    sub_info_7777: "A causa della forma unica della sua testa e della sua fronte, funziona come una potente macchina fotografica o un telescopio, in grado di osservare distanze molto oltre Tamima per chilometri.",
    sub_info_3344: "Con i suoi occhi multipli e la sua grande orecchia, possiede eccellenti capacità di lettura labiale e udito. Può comprendere le lingue di tutti gli altri pianeti usando i suoi occhi, analizzarle con la sua orecchia, e avvisare la regina se rileva qualsiasi pericolo da altre creature.",
    sub_info_6666: "Ci sono cinque macchie rosse sul suo viso e corpo che emettono un allarme se qualcuno tenta di lasciare Tamima senza permesso. Le sue ciglia cambiano forma rapidamente per rilevare la direzione da cui la persona sta uscendo e impediscono loro di lasciare il pianeta.",
    sub_info_5555: "La sua testa può staccarsi dal corpo. Durante la separazione, sia la testa che il corpo rimangono vivi, permettendole di essere presente in due luoghi contemporaneamente se necessario.",
    sub_info_2233: "Grazie alle forme geometriche e matematiche nelle sue ciglia e nel corpo, ha il potere di cambiare alcune case su Tamima che necessitano di modifiche.",
    sub_info_333: "Con la sua espressione facciale gentile, può trasformare la tristezza in gioia se rileva dolore in uno degli abitanti di Tamima. Il suo viso blu porta loro pace.",
    sub_info_1122: "La capacità di questa creatura di creare un arcobaleno, dovuta ai suoi occhi dissimili e a un pezzo insolito sulla parte posteriore della sua testa, è davvero notevole. Ruotando i suoi occhi, forma un arcobaleno con tre colori: blu, giallo e grigio. A differenza dell'arcobaleno terrestre, questo arcobaleno circolare migliora la bellezza misteriosa di Tamima.",
    //! ------------------------ Roadmap
    roadmapTitle: "La Tamima [Roadmap]",
    phase: "Fase",
    unknowPhase: "Si sta pianificando il proseguimento della roadmap!",
    rmp_1_title: "Costruzione delle fondamenta",
    rmp_101: "Stabilireremo la presenza digitale della nostra collezione sulle principali piattaforme come Opensea e sui social media (Twitter, Instagram e Discord). Condividendo regolarmente contenuti e aggiornamenti, costruiremo coinvolgimento e interesse nella comunità.",
    rmp_102: "Annunceremo il lancio del nostro sito web e promuoveremo l'aspetto unico e fatto a mano dei nostri NFT, evidenziando che ogni pezzo è disegnato con cuore e anima.",
    rmp_103: "Organizzeremo un evento di lancio online per introdurre la nostra collezione NFT, offrendo anteprime e sguardi dietro le quinte del processo creativo.",
    rmp_2_title: "Espansione della comunità",
    rmp_201: "Creeremo contenuti giornalieri o settimanali su Twitter per raccontare le storie dei nostri NFT, le loro origini e il percorso creativo che li ha ispirati. Inviteremo la comunità a partecipare, condividere i propri pensieri e discutere.",
    rmp_202: "Collaboreremo con influencer e comunità NFT per mostrare la nostra collezione. Collaborare con figure chiave ci aiuterà a costruire credibilità ed espandere la nostra portata.",
    rmp_3_title: "Sviluppo delle utilità",
    rmp_301: "Introdurremo vantaggi esclusivi per i collezionisti, come l'accesso a NFT in edizione limitata, contenuti dietro le quinte e diritti di voto nei futuri sviluppi del progetto.",
    rmp_302: "Svilupperemo un programma di adesione in cui i collezionisti riceveranno accesso anticipato ai futuri drop, regali speciali o esperienze esclusive legate alla nostra arte.",
    rmp_4_title: "Crescita a lungo termine e eredità",
    rmp_401: "Inizieremo a coinvolgere le comunità NFT, lanciando collaborazioni con altri artisti e piattaforme. Costruendo relazioni solide, favoriremo connessioni durature e crescita a lungo termine.",
    rmp_402: "Svilupperemo un piano di espansione che includa prodotti fisici o esperienze basate sui nostri personaggi NFT, colmando ulteriormente il divario tra arte digitale e fisica.",
    //! ------------------------ WhitePaper
    whitePaperTitle: "The Tamima NFT Collection [Whitepaper]",
    wp_1_title: "Introduzione",
    wp_1_text: "The_Tamima è una collezione unica e straordinaria di NFT, realizzata a mano, infusa di cuore, anima e immaginazione. Ogni pezzo racconta una storia dalle profondità dell'universo creativo dell'artista, catturando la bellezza del mistico e dello straordinario. La collezione mira a connettere i collezionisti a un senso più profondo dell'arte, creando al contempo valore per la comunità NFT. Con la missione di raggiungere gli amanti dell'arte, i collezionisti e coloro che hanno una passione per lo straordinario, The_Tamima è un mix di espressione personale e artigianato di alta qualità.",
    wp_2_title: "Visione",
    wp_2_text: "Creare un impatto duraturo nel mondo NFT fondendo l'arte fatta a mano con l'innovazione digitale, offrendo valore a lungo termine ed esperienze memorabili ai collezionisti di tutto il mondo. Attraverso la collezione The_Tamima, miriamo a promuovere una comunità forte e solidale di appassionati di NFT che apprezzino la bellezza dell'arte e il valore della narrazione.",
    wp_3_title: "Missione",
    wp_3_text: "La nostra missione è quella di costruire un mondo in cui ogni collezionista si senta connesso alle storie uniche dietro l'opera d'arte che possiede. Vogliamo ispirare gli altri attraverso la nostra arte artigianale, offrendo al contempo vantaggi tangibili a coloro che scelgono di far parte di questo viaggio. Offrendo vari tipi di NFT a diversi prezzi, garantiamo che ci sia un punto di accesso per tutti, dagli acquirenti alle prime armi ai collezionisti esperti.",
    wp_4_title: "La collezione",
    wp_4_text: "La collezione NFT The_Tamima è composta da un numero limitato di opere d'arte disegnate a mano, ogni pezzo meticolosamente realizzato con dettagli che riflettono l'immaginazione e la passione dell'artista. La gamma di personaggi e creature all'interno di questa collezione è una testimonianza di creatività e innovazione, ognuno dei quali possiede poteri, abilità e tradizioni unici che troveranno riscontro nei collezionisti.",
    wp_5_title: "Utilità e vantaggi",
    wp_5_text: "Offriamo un sistema di vantaggi a livelli in base al tipo di NFT acquistato:@BR[1.] Livello base: accesso a contenuti esclusivi dietro le quinte, tra cui registrazioni video del processo di creazione e accesso anticipato ai nuovi drop NFT.@BR[2.] Livello intermedio: accesso anticipato a collaborazioni, offerte speciali e possibilità di vincere commissioni di opere d'arte personalizzate uno a uno.@BR[3.] Livello Premium: i possessori di NFT di livello premium avranno diritto a un canale diretto con l'artista, che consentirà esperienze personalizzate, inviti a eventi virtuali privati e informazioni anticipate su progetti futuri.",
    wp_6_title: "Roadmap",
    wp_6_text: "[Fase 1]: Costruire le fondamenta@BR- Stabilire una forte presenza su piattaforme come OpenSea, social media e un sito Web dedicato.@BR- Ospitare un evento online per presentare ufficialmente la collezione al pubblico.@BR[Fase 2]: Ampliare la community@BR- Costruire il coinvolgimento della community attraverso la narrazione e le esperienze interattive.@BR- Collaborare con influencer e artisti per presentare gli NFT di The_Tamima.@BR[Fase 3]: Sviluppo dell'utilità@BR- Distribuire vantaggi esclusivi ai possessori di NFT, come contenuti personalizzati e accesso a drop futuri.@BR[Fase 4]: Crescita a lungo termine@BR- Sviluppare collaborazioni con artisti e marchi per creare valore duraturo.@BR- Esplorare prodotti fisici ed esperienze legate al mondo digitale di The_Tamima.",
    wp_7_title: "Conclusione",
    wp_7_text: "La collezione NFT The_Tamima non è solo un altro asset digitale, ma un ponte tra l'arte tradizionale e la nuova frontiera degli NFT. Il nostro obiettivo è coinvolgere i collezionisti con pezzi unici e artigianali che offrono più della semplice proprietà: offrono una connessione, una storia e un'esperienza. Ti invitiamo a far parte di questo viaggio, mentre continuiamo a spingere i confini della creatività e della comunità nel mondo degli NFT.", 
}