import { useTranslation } from "react-i18next";
import { WhitePaper } from "Utils/Constants/WhitePaper";
export default function WhitePaperDrawer() {
    const { t } = useTranslation();
    const htmlDecode_text = (text) => {
        const e = document.createElement('p');
        e.innerHTML = text.replaceAll('@BR','</br>').replaceAll('[','<span class="text-blue">').replaceAll(']','</span>');
        return e.childNodes.length === 0 ? "" : e.innerHTML;
    }
    return (
        <>
            {
                WhitePaper.map(item => {
                    return (
                        <div className="each-wp" key={`whitePaper-${item.id}`}>
                            <div className="wp-icon">
                                <img src={item.icon} alt={item.title} />
                                <strong className="set-Audiowide-font d-block d-md-none">{t(item.title)}</strong>
                            </div>
                            <div className="wp-info">
                                <strong className="set-Audiowide-font d-none d-md-block">{t(item.title)}</strong>
                                <p dangerouslySetInnerHTML={{ __html: htmlDecode_text(t(item.description))}}/>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}