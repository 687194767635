export const MenuItems = [
    {
        id: 1,
        title: "Home",
        action: '/',
        active: true,
        subMenu: [
            {
                id: 11,
                title: "Roadmap",
                action: '/roadmap',
                active: true,
            },{
                id: 12,
                title: "Whitepaper",
                action: '/whitepaper',
                active: true,
            },
        ],
    },{
        id: 2,
        title: "Tamima",
        action: '/tamima',
        active: true,
        subMenu: [],
    },{
        id: 3,
        title: "Characters",
        action: '/characters',
        active: true,
        subMenu: [],
    },{
        id: 4,
        title: "Contact us",
        action: '/contactus',
        active: true,
        subMenu: [],
    },
]