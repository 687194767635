import React from 'react';
import CharShow from './CharShow';
import MainPara from 'Screens/components/MainPara';
import { Characters } from 'Utils/Constants/Characters';
export default function AllCharShow({ show, selected, onClick}) {
    const onSelectChar = (row) => {
        try {onClick(row);
        } catch {}
    };
    return (
        <div className={`base ${show ? `on-${!selected ? 'show' : 'hide'}-mode-all` : ''}`}>
            {!selected && <>
                <MainPara title={'charactersPageTitle'} text={'charactersPageText'} charType align={'text-left'}/>
                <div className='char-body'>
                    {Characters.map(item => {return (
                        <CharShow
                            key={'characters-' + item.id}
                            character={item}
                            onClick={() => onSelectChar(item)}
                            effect
                        />
                    )})}
                </div>
            </>}
        </div>
    )
}