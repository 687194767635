export const enTexts = {
    welcome: "The [Emanations] of My Mind",
    welcomeText: "Welcome to a world where imagination takes form and creativity becomes reality. Every piece you see here begins as a spark in my mind, evolving into extraordinary characters. When I am painting these myths, I feel that they are being born in another dimension.@BRExplore the Tamima page to witness my artistic process firsthand, as I transform these thoughts into vivid, hand-drawn artworks right before your eyes. Immerse yourself in the experience and connect with the essence of art like never before.",
    homeSecondParaTitle: 'What is an [NFT] ?@BRHow Can You [Own] One ?',
    homeSecondParaText: 'NFTs, or Non-Fungible Tokens, are digital collectibles that use blockchain technology to ensure each piece is unique and can’t be replicated. By turning my hand-drawn art into NFTs, I’m offering you the chance to own exclusive digital pieces that are verifiably original. These digital assets allow you to support the art while also holding a piece of digital history!',
    homeThirdParaTitle: 'How to [buy] ?',
    homeThirdParaText: 'To own one of my NFTs, you’ll need a cryptocurrency wallet and some Ethereum (ETH). Here’s a quick guide to get started:@BR[1. Set Up Your Wallet:] Download a wallet app like MetaMask or Trust Wallet, then follow the steps to create your wallet.@BR[2. Buy Ethereum (ETH):] Add Ethereum to your wallet from an exchange like Coinbase or Binance.@BR[3. Connect Your Wallet:] Head over to NFT marketplaces (like OpenSea) and connect your wallet to start browsing and purchasing NFTs.',
    homeFourthParaTitle: "Explore the [collection]@BR{and}@BRjoin the [digital art revolution] !",

    charactersPageTitle: 'My [Characters]',
    charactersPageText: "All these characters and beings originate from my soul, from my subconscious And whoever attracted you, the story originates from your soul and your subconscious for you and only for you There is no limit to the stories And whoever connects with you emotionally and touches your soul You can find its story in the depths of your being where the origin of the universe is.@BRPay attention, I said that you can find the story of any character that attracted you because it is close to your soul.@BRI would be happy if you share your story with me.",
    charactersCodeShow: 'Characters Code [@P1]',
    charactersCodeText: 'Lorem Ipsum is simply dummy text of the printing and  typesetting industry.',
    otherShapeOfCharacter: 'Other [Character]',
    character_1_description: "The queen is a uniquely beautiful being with extraordinary eyes that see beyond the ordinary. Her gaze is filled with love, but when justice calls, her eyes turn fiery, restoring balance with a mere glance. Her eyelashes carry the scent of spring and vitality, a divine essence wherever she is. This heavenly aroma is unlike anything ever experienced. Her ears can hear even the faintest whispers in the galaxy, processing them through six underlying layers before she decides whether to speak. Her mind transcends time, understanding the need for a meaningful life. Her body, made from pure divine silk, carries powers she uses wisely and gracefully.",
    character_2_description: "Due to its high capabilities, it is responsible for communication with the creative forces of other planets.@BRWith its mouth and the symbols on its face, it establishes connections with other planets. Through its ears, it listens to their messages and assesses whether these other planets have the ability to communicate with the planet Tamima. If they do, a connection with Tamima is established, utilizing their creativity for the benefit of Tamima.@BRFurthermore, thanks to its remarkable symbols and the high energy within its body, it is able to almost control the rotation of the planet Tamima.",
    character_3_description: "Blue Legends",
    character_4_description: "New Collection",

    tamimaPageTitle: 'The Beginning of a [Mystical] Journey',
    tamimaPageText: 'Everything started on the day I was meditating to calm my mind and body... I had my eyes closed and drifted into an amazing dream. In this dream, I saw a planet full of extraordinary creatures, all looking at me with deep and meaningful eyes, carrying an unspoken desire. I felt that my soul, thanks to the tranquility I had found, had entered their world. Seeing them ignited a burning passion in me to depict them through my art. And I began to draw...',
    tamimaSecondParaTitle: 'Guided by [Unseen] Forces',
    tamimaSecondParaText: 'With the first line drawn on the paper, my conscious mind seemed to switch off, and my subconscious took over. Time stopped; I no longer heard the sounds around me and all my senses were immersed in another world. While drawing these extraordinary creatures, I heard their voices in my mind, each speaking in a friendly and mysterious tone, urging me to bring them into our world. A powerful force connected to my brain, guiding my hands. As I drew, the voice of each creature whispered in my mind, guiding me on how to capture their image beautifully. They guided every line and curve, telling me exactly how my hand should move.',
    tamimaThirdParaTitle: 'Creation Beyond [Time] and [Space]',
    tamimaThirdParaText: 'Choosing colors became an intimate conversation; they told me which colors best represented them. Some drawings took shape easily in a few minutes, while others took hours, each line and color guided by the voices. Throughout this time, I worked in silence, listening only to the instructions given to me. In the process of drawing, they described their appearance to me and told me what details to add, including inscriptions on their bodies and the numbers used in them, all through the voices in my mind. After completing each design, I felt a sense of fatigue similar to after hours of practice, tired but enthusiastic, as a lot of energy had been put into each work. When I look at my works, I often find myself in wonder as I don’t remember some details, as these paintings originate entirely from my subconscious, without the influence of my conscious mind. I am deeply grateful for this unique gift – the ability to depict these creatures and share them with the world. I am certain that these creatures exist somewhere in the galaxy and are eager to be seen. Perhaps in the near future, we can see these creatures in the real world.',
    
    contactusTitle: '[Contact] us',
    contactusText: 'through the following ways',
    emailTitle: 'Direct [Contact]',
    emailPlaceHolder: 'Type something ...',
    emailSend: 'Send',
    successEmail: 'Email sent successfully',
    successEmailThank: 'Thank you for your participation',
    //! ------------------------ Characters
    sub_info_4466: "A creature without the power of speech communicates through its actions. It detects lies through the yellow dots on its body and responds using red beams from its body. If angered, the beam turns redder, while in calm states, the beam softens.",
    sub_info_3333: "This creature has a circular mark on its head that absorbs negative energy from the planet and neutralizes it.",
    sub_info_2222: "With a special power located in its mouth, this being prevents the overgrowth of trees on the planet Tamima, pruning them to maintain balance.",
    sub_info_1133: "If any unwanted plants try to grow, this creature uses its eyelashes to dry them up, transforming them into soil similar to Tamima's.",
    sub_info_3322: "It possesses a remarkable sense of smell, capable of detecting and repelling harmful insects attempting to invade Tamima from other planets.",
    sub_info_8899: "With its eyes and eyelashes, it fertilizes and tills Tamima's lands, preparing them to grow a special type of plant that protects the planet.",
    sub_info_7788: "Its face, resembling ears, gives it excellent hearing. It catches even the slightest sounds and sends them to the queen for analysis using its eyelashes. (One of the queen’s abilities is to understand the true meaning behind words.)",
    sub_info_6677: "It absorbs all the purple light of Tamima, categorizes it using its eyelashes, and distributes the light to areas needing energy.",
    sub_info_5566 : "Its head acts as a radar, capable of examining strange rays or lights. It directs these rays to the planet's left side, neutralizing any potential threats.",
    sub_info_111: "Its duty is to review Tamima's documents, stamping them for approval with its eyelashes before sending them to the queen.",
    sub_info_4444: "This being is highly aware and thoughtful about matters. It speaks wisely, carefully pausing before responding to anything it hears. It does not speak on a subject unless it has seen it for itself.",
    sub_info_9999: "Due to the numbers on its face, it is responsible for calculating the number of meteorites and the distances between planets.",
    sub_info_4455: "With its red-colored ears and eyes, it has heightened vision and hearing. It can detect tiny insects under Tamima's soil and uses the symbol on its head to transform these insects into helpful and beneficial creatures for the planet.",
    sub_info_8888: "This creature specializes in identifying any stranger attempting to enter Tamima. It has the ability to recognize individuals who have changed their appearance.",
    sub_info_7777: "Because of its uniquely shaped head and forehead, it functions like a powerful camera or telescope, capable of observing distances far beyond Tamima for miles.",
    sub_info_3344: "With its multiple eyes and large ear, it possesses excellent lip-reading and hearing abilities. It can understand the languages of all other planets using its eyes, analyze them with its ear, and alert the queen if any danger is detected from other beings.",
    sub_info_6666: "There are five red spots on its face and body, which sound an alarm if anyone tries to leave Tamima without permission. Its eyelashes quickly change shape to detect the direction the person is leaving from and prevent them from leaving the planet.",
    sub_info_5555: "Its head can detach from its body. During separation, both its head and body remain alive, allowing it to be present in two places at once if needed.",
    sub_info_2233: "With the geometric and mathematical shapes in its eyelashes and body, it has the power to change certain homes on Tamima that need alterations.",
    sub_info_333: "With its kind facial expression, it can transform sadness into joy if it detects sorrow in any of Tamima’s inhabitants. Its blue face brings peace to them.",
    sub_info_1122: "This creature’s ability to create a rainbow, due to its mismatched eyes and an unusual piece on the back of its head, is truly remarkable. By spinning its eyes, it forms a rainbow with three colors: blue, yellow, and gray. Unlike Earth’s rainbow, this circular rainbow enhances the mysterious beauty of Tamima.",
    //! ------------------------ Roadmap
    roadmapTitle: "The Tamima [Roadmap]",
    phase: "Phase",
    unknowPhase: "The continuation of the roadmap is being planned!",
    rmp_1_title: "Building the Foundation",
    rmp_101: "We will establish our collection's digital presence across key platforms like Opensea and social media (Twitter, Instagram, and Discord). Regularly sharing content and updates will help us build engagement and community interest.",
    rmp_102: "We will announce the launch of our website and promote the unique handmade aspect of our NFTs, highlighting that each piece is drawn with heart and soul.",
    rmp_103: "We will organize an online launch event to introduce our NFT collection, offering sneak peeks and behind-the-scenes insights into the creative process.",
    rmp_2_title: "Expanding the Community",
    rmp_201: "We will create daily or weekly Twitter content that shares stories about our NFTs, their origins, and the creative journey behind them. We’ll invite the community to engage, share their thoughts, and discuss.",
    rmp_202: "We will collaborate with influencers and NFT communities to showcase our collection. Partnering with key figures will help build credibility and expand our reach in the space.",
    rmp_3_title: "Utility Development",
    rmp_301: "We will introduce exclusive benefits for collectors, such as access to limited edition NFTs, behind-the-scenes content, and voting rights in future developments of our project.",
    rmp_302: "We will develop a membership program where collectors receive early access to future drops, special gifts, or exclusive experiences tied to our art.",
    rmp_4_title: "Long-term Growth and Legacy",
    rmp_401: "We will start engaging with NFT communities and launch collaborations with other artists and platforms. Building strong relationships will help foster long-term connections and growth.",
    rmp_402: "We will develop an expansion plan that includes physical products or experiences based on our NFT characters, further bridging the gap between digital and physical art.",
    //! ------------------------ WhitePaper
    whitePaperTitle: "The Tamima NFT Collection [Whitepaper]",
    wp_1_title: "Introduction",
    wp_1_text: "The_Tamima is a unique and extraordinary collection of NFTs, crafted by hand, infused with heart, soul, and imagination. Each piece tells a story from the depths of the artist’s creative universe, capturing the beauty of the mystical and the extraordinary. The collection aims to connect collectors to a deeper sense of art while creating value for the NFT community. With a mission to reach art lovers, collectors, and those with a passion for the extraordinary, The_Tamima is a blend of personal expression and high-quality craftsmanship.",
    wp_2_title: "Vision",
    wp_2_text: "To create a lasting impact in the NFT world by blending handmade art with digital innovation, providing long-term value and memorable experiences to collectors worldwide. Through The_Tamima collection, we aim to foster a strong, supportive community of NFT enthusiasts who appreciate the beauty of art and the value of storytelling.",
    wp_3_title: "Mission",
    wp_3_text: "Our mission is to build a world where every collector feels connected to the unique stories behind the artwork they own. We want to inspire others through our handcrafted art, while offering tangible benefits to those who choose to be a part of this journey. By offering various types of NFTs at different price points, we ensure that there’s an entry point for everyone, from novice buyers to seasoned collectors.",
    wp_4_title: "The Collection",
    wp_4_text: "The_Tamima NFT collection consists of a limited number of hand-drawn artworks, each piece meticulously crafted with details that reflect the artist’s imagination and passion. The range of characters and creatures within this collection serve as a testament to creativity and innovation, each possessing unique powers, abilities, and lore that will resonate with collectors.",
    wp_5_title: "Utility and Benefits",
    wp_5_text: "We provide a tiered system of benefits based on the type of NFT purchased:@BR[1.] Basic Tier: Access to exclusive behind-the-scenes content, including video recordings of the creation process and early access to new NFT drops.@BR[2.] Mid Tier: Early access to collaborations, special offers, and a chance to win one-on-one personalized artwork commissions.@BR[3.] Premium Tier: Holders of the premium-tier NFTs will be entitled to a direct channel with the artist, allowing for personalized experiences, invitations to private virtual events, and early information on future projects.",
    wp_6_title: "Roadmap",
    wp_6_text: "[Phase 1]: Building the Foundation@BR- Establishing a strong presence on platforms such as OpenSea, social media, and a dedicated website.@BR- Hosting an online event to officially introduce the collection to the public.@BR[Phase 2]: Expanding the Community@BR- Building community engagement through storytelling and interactive experiences.@BR- Collaborating with influencers and artists to showcase The_Tamima NFTs.@BR[Phase 3]: Utility Development@BR- Rolling out exclusive benefits to NFT holders, such as personalized content and access to future drops.@BR[Phase 4]: Long-term Growth@BR- Developing collaborations with artists and brands to create lasting value.@BR- Exploring physical products and experiences tied to the digital world of The_Tamima.",
    wp_7_title: "Conclusion",
    wp_7_text: "The_Tamima NFT collection is not just another digital asset but a bridge between traditional art and the new frontier of NFTs. Our goal is to engage collectors with unique, handcrafted pieces that offer more than just ownership – they offer a connection, a story, and an experience. We invite you to be a part of this journey, as we continue to push the boundaries of creativity and community in the world of NFTs.", 
}