import { useTranslation } from "react-i18next";
import { Roadmap } from "Utils/Constants/Roadmap";
import * as Icons from 'react-bootstrap-icons';
import CircularProgress from '@mui/material/CircularProgress';
export default function RoadmapSteps() {
    const { t } = useTranslation();
    return (
        <>
            {
                Roadmap.map(item => {
                    return (
                        <div id={`phase${item.id}`} className="each-phase" key={`phase-${item.id}`}>
                            <div className="inner-content">
                                <div className="inner-head">
                                    <p className="index set-Audiowide-font">
                                        {`${t('phase')} ${item.phase}`}
                                    </p>
                                    <p className="set-Audiowide-font">
                                        {t(item.title)}
                                    </p>
                                </div>
                                {
                                    item.levels.map(i => {
                                        return (
                                            <div className={`inner-phase ${i.status === 4 ? 'disable' : '' }`} key={`phase-${item.id}-${i.id}`}>
                                                <div className="inner-phase-status">
                                                    <div className={`inner-phase-icon status-${i.status} ${i.value === 100 ? 'done' : 'proccess' } `}>
                                                        {
                                                            (i.status === 2 && i.value !== 100) &&
                                                            <div className="circular-progress">
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    size={55}
                                                                    value={i.value}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            {
                                                                1: <Icons.CheckCircleFill />,
                                                                2: <Icons.PlayCircleFill />,
                                                                3: <Icons.StopCircleFill />,
                                                                4: <Icons.SlashCircleFill />,
                                                            }[i.status]
                                                        }
                                                    </div>
                                                    {i.status !== 4 && <p className="inner-phase-step d-block d-md-none">{t(i.step)}</p>}
                                                </div>
                                                <p className="inner-phase-description">{t(i.description)}</p>
                                                {i.status !== 4 && <p className="inner-phase-step d-none d-md-block">{t(i.step)}</p>}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}