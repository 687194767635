import React from 'react';
import MainLayout from 'Screens/components/MainLayout';
import MainPara from 'Screens/components/MainPara';
import { Social } from 'Utils/Constants/Social';
import EmailSender from 'Screens/components/EmailSender';
// -- image
import Contact from "Utils/Assets/Images/ContactUs.webp";
// -- image
export default function ContactusIndex() {
    return (
        <MainLayout
            pageContent={
                <>
                    <MainPara title={'contactusTitle'} text={'contactusText'} social={Social}/>
                    <EmailSender />
                </>
            }
            imageSrc={Contact}
            imageAlt={'ContactPageImage'}
        />
    );
}