import { useNavigate } from 'react-router-dom';
import { move2Top } from 'Utils/Helpers/ApiServices';
export default function IButtonSpc({ icon, label, link}) {
    const navigate = useNavigate();
    const handlePush = () => {
        navigate(link);
        move2Top();
    }
    return (
        <button onClick={handlePush} className='spc-button'>
           <div>
                {icon}
                <p>{label}</p>
            </div>
        </button>
    );
}