import React from 'react';
import MainLayout from 'Screens/components/MainLayout';
import MainPara from 'Screens/components/MainPara';
import HomeSocial from 'Screens/components/HomeSocial';
import HomeButtons from 'Screens/components/HomeButtons';
// -- image
import HomePage from "Utils/Assets/Images/HomePage.webp";
// -- image
export default function HomeIndex() {
    return (
        <MainLayout
            centered
            pageContent={
                <>
                    <MainPara title={'welcome'} text={'welcomeText'} />
                    <MainPara title={'homeSecondParaTitle'} text={'homeSecondParaText'} />
                    <MainPara title={'homeThirdParaTitle'} text={'homeThirdParaText'} />
                    <MainPara title={'homeFourthParaTitle'} />
                    <HomeButtons />
                    <HomeSocial />
                </>
            }
            imageSrc={HomePage}
            imageAlt={'HomePageImage'}
        />
    );
}