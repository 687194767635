import Introduction from '../../Utils/Assets/Images/Icons/Introduction.png';
import Vision from '../../Utils/Assets/Images/Icons/Vision.png';
import Mission from '../../Utils/Assets/Images/Icons/Mission.png';
import Collection from '../../Utils/Assets/Images/Icons/Collection.png';
import UtilityBenefits from '../../Utils/Assets/Images/Icons/UtilityBenefits.png';
import Roadmap2 from '../../Utils/Assets/Images/Icons/roadmap2.png';
import Conclusion from '../../Utils/Assets/Images/Icons/Conclusion.png';

export const WhitePaper = [
    {
        id: 1,
        icon: Introduction,
        title: 'wp_1_title',
        description: 'wp_1_text',
    },{
        id: 2,
        icon: Vision,
        title: 'wp_2_title',
        description: 'wp_2_text',
    },{
        id: 3,
        icon: Mission,
        title: 'wp_3_title',
        description: 'wp_3_text',
    },{
        id: 4,
        icon: Collection,
        title: 'wp_4_title',
        description: 'wp_4_text',
    },{
        id: 5,
        icon: UtilityBenefits,
        title: 'wp_5_title',
        description: 'wp_5_text',
    },{
        id: 6,
        icon: Roadmap2,
        title: 'wp_6_title',
        description: 'wp_6_text',
    },{
        id: 7,
        icon: Conclusion,
        title: 'wp_7_title',
        description: 'wp_7_text',
    },
]