import MainLayout from "Screens/components/MainLayout";
import MainPara from "Screens/components/MainPara";
import FutureRoadmap from "./components/FutureRoadmap";
import RoadmapSteps from "./components/RoadmapSteps";
export default function RoadmapIndex() {
    return (
        <MainLayout
            flat
            pageContent={
                <>
                    <MainPara title={'roadmapTitle'} charType align={'text-left'}/>
                    <div className="info-page">
                        <RoadmapSteps />
                        <FutureRoadmap />
                    </div>
                </>
            }
        />
    );
}