// ----- images
// -- Category 1
import Char1 from 'Utils/Assets/Images/Characters/Char1.webp';
import Char11 from 'Utils/Assets/Images/Characters/Char1-1.webp';
import Char12 from 'Utils/Assets/Images/Characters/Char1-2.webp';
import Char13 from 'Utils/Assets/Images/Characters/Char1-3.webp';
import Char14 from 'Utils/Assets/Images/Characters/Char1-4.webp';
import Char15 from 'Utils/Assets/Images/Characters/Char1-5.webp';
import Char16 from 'Utils/Assets/Images/Characters/Char1-6.webp';
import Char17 from 'Utils/Assets/Images/Characters/Char1-7.webp';
import Char18 from 'Utils/Assets/Images/Characters/Char1-8.webp';
import Char19 from 'Utils/Assets/Images/Characters/Char1-9.webp';
import Char110 from 'Utils/Assets/Images/Characters/Char1-10.webp';
import Char111 from 'Utils/Assets/Images/Characters/Char1-11.webp';
import Char112 from 'Utils/Assets/Images/Characters/Char1-12.webp';
// -- Category 2
import Char2 from 'Utils/Assets/Images/Characters/Char2.webp';
import Char21 from 'Utils/Assets/Images/Characters/Char2-1.webp';
import Char22 from 'Utils/Assets/Images/Characters/Char2-2.webp';
import Char23 from 'Utils/Assets/Images/Characters/Char2-3.webp';
import Char24 from 'Utils/Assets/Images/Characters/Char2-4.webp';
import Char25 from 'Utils/Assets/Images/Characters/Char2-5.webp';
import Char26 from 'Utils/Assets/Images/Characters/Char2-6.webp';
import Char27 from 'Utils/Assets/Images/Characters/Char2-7.webp';
import Char28 from 'Utils/Assets/Images/Characters/Char2-8.webp';
import Char29 from 'Utils/Assets/Images/Characters/Char2-9.webp';
import Char210 from 'Utils/Assets/Images/Characters/Char2-10.webp';
import Char211 from 'Utils/Assets/Images/Characters/Char2-11.webp';
import Char212 from 'Utils/Assets/Images/Characters/Char2-12.webp';
import Char213 from 'Utils/Assets/Images/Characters/Char2-13.webp';
import Char214 from 'Utils/Assets/Images/Characters/Char2-14.webp';
import Char215 from 'Utils/Assets/Images/Characters/Char2-15.webp';
import Char216 from 'Utils/Assets/Images/Characters/Char2-16.webp';
import Char217 from 'Utils/Assets/Images/Characters/Char2-17.webp';
import Char218 from 'Utils/Assets/Images/Characters/Char2-18.webp';
import Char219 from 'Utils/Assets/Images/Characters/Char2-19.webp';
import Char220 from 'Utils/Assets/Images/Characters/Char2-20.webp';
import Char221 from 'Utils/Assets/Images/Characters/Char2-21.webp';
import Char222 from 'Utils/Assets/Images/Characters/Char2-22.webp';
import Char223 from 'Utils/Assets/Images/Characters/Char2-23.webp';
import Char224 from 'Utils/Assets/Images/Characters/Char2-24.webp';
import Char225 from 'Utils/Assets/Images/Characters/Char2-25.webp';
import Char226 from 'Utils/Assets/Images/Characters/Char2-26.webp';
import Char227 from 'Utils/Assets/Images/Characters/Char2-27.webp';
import Char228 from 'Utils/Assets/Images/Characters/Char2-28.webp';
import Char229 from 'Utils/Assets/Images/Characters/Char2-29.webp';
import Char230 from 'Utils/Assets/Images/Characters/Char2-30.webp';
import Char231 from 'Utils/Assets/Images/Characters/Char2-31.webp';
import Char232 from 'Utils/Assets/Images/Characters/Char2-32.webp';
import Char233 from 'Utils/Assets/Images/Characters/Char2-33.webp';
import Char234 from 'Utils/Assets/Images/Characters/Char2-34.webp';
import Char235 from 'Utils/Assets/Images/Characters/Char2-35.webp';
import Char236 from 'Utils/Assets/Images/Characters/Char2-36.webp';
import Char237 from 'Utils/Assets/Images/Characters/Char2-37.webp';
import Char238 from 'Utils/Assets/Images/Characters/Char2-38.webp';
import Char239 from 'Utils/Assets/Images/Characters/Char2-39.webp';
import Char240 from 'Utils/Assets/Images/Characters/Char2-40.webp';
import Char241 from 'Utils/Assets/Images/Characters/Char2-41.webp';
import Char242 from 'Utils/Assets/Images/Characters/Char2-42.webp';
import Char243 from 'Utils/Assets/Images/Characters/Char2-43.webp';
import Char244 from 'Utils/Assets/Images/Characters/Char2-44.webp';
import Char245 from 'Utils/Assets/Images/Characters/Char2-45.webp';
import Char246 from 'Utils/Assets/Images/Characters/Char2-46.webp';
import Char247 from 'Utils/Assets/Images/Characters/Char2-47.webp';
import Char248 from 'Utils/Assets/Images/Characters/Char2-48.webp';
import Char249 from 'Utils/Assets/Images/Characters/Char2-49.webp';
import Char250 from 'Utils/Assets/Images/Characters/Char2-50.webp';
import Char251 from 'Utils/Assets/Images/Characters/Char2-51.webp';
import Char252 from 'Utils/Assets/Images/Characters/Char2-52.webp';
import Char253 from 'Utils/Assets/Images/Characters/Char2-53.webp';
import Char254 from 'Utils/Assets/Images/Characters/Char2-54.webp';
import Char255 from 'Utils/Assets/Images/Characters/Char2-55.webp';
import Char256 from 'Utils/Assets/Images/Characters/Char2-56.webp';
import Char257 from 'Utils/Assets/Images/Characters/Char2-57.webp';
import Char258 from 'Utils/Assets/Images/Characters/Char2-58.webp';
import Char259 from 'Utils/Assets/Images/Characters/Char2-59.webp';
import Char260 from 'Utils/Assets/Images/Characters/Char2-60.webp';
import Char261 from 'Utils/Assets/Images/Characters/Char2-61.webp';
import Char262 from 'Utils/Assets/Images/Characters/Char2-62.webp';
// -- Category 3
import Char3 from 'Utils/Assets/Images/Characters/Char3.webp';
import Char31 from 'Utils/Assets/Images/Characters/Char3-1.webp';
import Char32 from 'Utils/Assets/Images/Characters/Char3-2.webp';
import Char33 from 'Utils/Assets/Images/Characters/Char3-3.webp';
import Char34 from 'Utils/Assets/Images/Characters/Char3-4.webp';
import Char35 from 'Utils/Assets/Images/Characters/Char3-5.webp';
import Char36 from 'Utils/Assets/Images/Characters/Char3-6.webp';
import Char37 from 'Utils/Assets/Images/Characters/Char3-7.webp';
import Char38 from 'Utils/Assets/Images/Characters/Char3-8.webp';
import Char39 from 'Utils/Assets/Images/Characters/Char3-9.webp';
import Char310 from 'Utils/Assets/Images/Characters/Char3-10.webp';
import Char311 from 'Utils/Assets/Images/Characters/Char3-11.webp';
import Char312 from 'Utils/Assets/Images/Characters/Char3-12.webp';
import Char313 from 'Utils/Assets/Images/Characters/Char3-13.webp';
import Char314 from 'Utils/Assets/Images/Characters/Char3-14.webp';
import Char315 from 'Utils/Assets/Images/Characters/Char3-15.webp';
import Char316 from 'Utils/Assets/Images/Characters/Char3-16.webp';
import Char317 from 'Utils/Assets/Images/Characters/Char3-17.webp';
import Char318 from 'Utils/Assets/Images/Characters/Char3-18.webp';
import Char319 from 'Utils/Assets/Images/Characters/Char3-19.webp';
import Char320 from 'Utils/Assets/Images/Characters/Char3-20.webp';
// -- Category 4
import Char4 from 'Utils/Assets/Images/Characters/Char4.webp';
import Char41 from 'Utils/Assets/Images/Characters/Char4-1.webp';
import Char42 from 'Utils/Assets/Images/Characters/Char4-2.webp';
import Char43 from 'Utils/Assets/Images/Characters/Char4-3.webp';
import Char44 from 'Utils/Assets/Images/Characters/Char4-4.webp';
import Char45 from 'Utils/Assets/Images/Characters/Char4-5.webp';
import Char46 from 'Utils/Assets/Images/Characters/Char4-6.webp';
import Char47 from 'Utils/Assets/Images/Characters/Char4-7.webp';
import Char48 from 'Utils/Assets/Images/Characters/Char4-8.webp';
import Char49 from 'Utils/Assets/Images/Characters/Char4-9.webp';
import Char410 from 'Utils/Assets/Images/Characters/Char4-10.webp';
import Char411 from 'Utils/Assets/Images/Characters/Char4-11.webp';
import Char412 from 'Utils/Assets/Images/Characters/Char4-12.webp';
import Char413 from 'Utils/Assets/Images/Characters/Char4-13.webp';
// ----- images

export const Characters = [
    {
        id: 1,
        code: '5555555',
        image: Char1,
        description: 'character_1_description',
        other: [
            {
                id: 101,
                code: '11011',
                image: Char11,
                more: '',
            },{
                id: 102,
                code: '11021',
                image: Char12,
                more: '',
            },{
                id: 103,
                code: '11031',
                image: Char13,
                more: '',
            },{
                id: 104,
                code: '11041',
                image: Char14,
                more: '',
            },{
                id: 105,
                code: '11051',
                image: Char15,
                more: '',
            },{
                id: 106,
                code: '11061',
                image: Char16,
                more: '',
            },{
                id: 107,
                code: '11071',
                image: Char17,
                more: '',
            },{
                id: 108,
                code: '11081',
                image: Char18,
                more: '',
            },{
                id: 109,
                code: '11091',
                image: Char19,
                more: '',
            },{
                id: 110,
                code: '11101',
                image: Char110,
                more: '',
            },{
                id: 111,
                code: '11111',
                image: Char111,
                more: '',
            },{
                id: 112,
                code: '11121',
                image: Char112,
                more: '',
            },
        ]
    },{
        id: 2,
        code: '1111',
        image: Char2,
        description: 'character_2_description',
        other: [
            {
                id: 201,
                code: '22012',
                image: Char21,
                more: 'sub_info_2222',
            },{
                id: 202,
                code: '22022',
                image: Char22,
                more: 'sub_info_3333',
            },{
                id: 203,
                code: '22032',
                image: Char23,
                more: 'sub_info_4444',
            },{
                id: 204,
                code: '22042',
                image: Char24,
                more: 'sub_info_5555',
            },{
                id: 205,
                code: '22052',
                image: Char25,
                more: 'sub_info_6666',
            },{
                id: 206,
                code: '22062',
                image: Char26,
                more: 'sub_info_7777',
            },{
                id: 207,
                code: '22072',
                image: Char27,
                more: 'sub_info_8888',
            },{
                id: 208,
                code: '22082',
                image: Char28,
                more: 'sub_info_9999',
            },{
                id: 209,
                code: '22092',
                image: Char29,
                more: 'sub_info_1122',
            },{
                id: 210,
                code: '22102',
                image: Char210,
                more: 'sub_info_2233',
            },{
                id: 211,
                code: '22112',
                image: Char211,
                more: 'sub_info_3344',
            },{
                id: 212,
                code: '22122',
                image: Char212,
                more: 'sub_info_4455',
            },{
                id: 213,
                code: '22132',
                image: Char213,
                more: 'sub_info_5566',
            },{
                id: 214,
                code: '22142',
                image: Char214,
                more: 'sub_info_6677',
            },{
                id: 215,
                code: '22152',
                image: Char215,
                more: 'sub_info_7788',
            },{
                id: 216,
                code: '22162',
                image: Char216,
                more: 'sub_info_8899',
            },{
                id: 217,
                code: '22172',
                image: Char217,
                more: 'sub_info_3322',
            },{
                id: 218,
                code: '22182',
                image: Char218,
                more: 'sub_info_1133',
            },{
                id: 219,
                code: '22192',
                image: Char219,
                more: 'sub_info_4466',
            },{
                id: 220,
                code: '22202',
                image: Char220,
                more: 'sub_info_111',
            },{
                id: 221,
                code: '22212',
                image: Char221,
                more: 'sub_info_333',
            },{
                id: 222,
                code: '22222',
                image: Char222,
                more: '',
            },{
                id: 223,
                code: '22232',
                image: Char223,
                more: '',
            },{
                id: 224,
                code: '22242',
                image: Char224,
                more: '',
            },{
                id: 225,
                code: '22252',
                image: Char225,
                more: '',
            },{
                id: 226,
                code: '22262',
                image: Char226,
                more: '',
            },{
                id: 227,
                code: '22272',
                image: Char227,
                more: '',
            },{
                id: 228,
                code: '22282',
                image: Char228,
                more: '',
            },{
                id: 229,
                code: '22292',
                image: Char229,
                more: '',
            },{
                id: 230,
                code: '22302',
                image: Char230,
                more: '',
            },{
                id: 231,
                code: '22312',
                image: Char231,
                more: '',
            },{
                id: 232,
                code: '22322',
                image: Char232,
                more: '',
            },{
                id: 233,
                code: '22332',
                image: Char233,
                more: '',
            },{
                id: 234,
                code: '22342',
                image: Char234,
                more: '',
            },{
                id: 235,
                code: '22352',
                image: Char235,
                more: '',
            },{
                id: 236,
                code: '22362',
                image: Char236,
                more: '',
            },{
                id: 237,
                code: '22372',
                image: Char237,
                more: '',
            },{
                id: 238,
                code: '22382',
                image: Char238,
                more: '',
            },{
                id: 239,
                code: '22392',
                image: Char239,
                more: '',
            },{
                id: 240,
                code: '22402',
                image: Char240,
                more: '',
            },{
                id: 241,
                code: '22412',
                image: Char241,
                more: '',
            },{
                id: 242,
                code: '22422',
                image: Char242,
                more: '',
            },{
                id: 243,
                code: '22432',
                image: Char243,
                more: '',
            },{
                id: 244,
                code: '22442',
                image: Char244,
                more: '',
            },{
                id: 245,
                code: '22452',
                image: Char245,
                more: '',
            },{
                id: 246,
                code: '22462',
                image: Char246,
                more: '',
            },{
                id: 247,
                code: '22472',
                image: Char247,
                more: '',
            },{
                id: 248,
                code: '22482',
                image: Char248,
                more: '',
            },{
                id: 249,
                code: '22492',
                image: Char249,
                more: '',
            },{
                id: 250,
                code: '22502',
                image: Char250,
                more: '',
            },{
                id: 251,
                code: '22512',
                image: Char251,
                more: '',
            },{
                id: 252,
                code: '22522',
                image: Char252,
                more: '',
            },{
                id: 253,
                code: '22532',
                image: Char253,
                more: '',
            },{
                id: 254,
                code: '22542',
                image: Char254,
                more: '',
            },{
                id: 255,
                code: '22552',
                image: Char255,
                more: '',
            },{
                id: 256,
                code: '22562',
                image: Char256,
                more: '',
            },{
                id: 257,
                code: '22572',
                image: Char257,
                more: '',
            },{
                id: 258,
                code: '22582',
                image: Char258,
                more: '',
            },{
                id: 259,
                code: '22592',
                image: Char259,
                more: '',
            },{
                id: 260,
                code: '22602',
                image: Char260,
                more: '',
            },{
                id: 261,
                code: '22612',
                image: Char261,
                more: '',
            },{
                id: 262,
                code: '22622',
                image: Char262,
                more: '',
            },
        ]
    },{
        id: 3,
        code: '222',
        image: Char3,
        description: 'character_3_description',
        other: [
            {
                id: 301,
                code: '33013',
                image: Char31,
                more: '',
            },{
                id: 302,
                code: '33023',
                image: Char32,
                more: '',
            },{
                id: 303,
                code: '33033',
                image: Char33,
                more: '',
            },{
                id: 304,
                code: '33043',
                image: Char34,
                more: '',
            },{
                id: 305,
                code: '33053',
                image: Char35,
                more: '',
            },{
                id: 306,
                code: '33063',
                image: Char36,
                more: '',
            },{
                id: 307,
                code: '33073',
                image: Char37,
                more: '',
            },{
                id: 308,
                code: '33083',
                image: Char38,
                more: '',
            },{
                id: 309,
                code: '33093',
                image: Char39,
                more: '',
            },{
                id: 310,
                code: '33103',
                image: Char310,
                more: '',
            },{
                id: 311,
                code: '33113',
                image: Char311,
                more: '',
            },{
                id: 312,
                code: '33123',
                image: Char312,
                more: '',
            },{
                id: 313,
                code: '33133',
                image: Char313,
                more: '',
            },{
                id: 314,
                code: '33143',
                image: Char314,
                more: '',
            },{
                id: 315,
                code: '33153',
                image: Char315,
                more: '',
            },{
                id: 316,
                code: '33163',
                image: Char316,
                more: '',
            },{
                id: 317,
                code: '33173',
                image: Char317,
                more: '',
            },{
                id: 318,
                code: '33183',
                image: Char318,
                more: '',
            },{
                id: 319,
                code: '33193',
                image: Char319,
                more: '',
            },{
                id: 320,
                code: '33203',
                image: Char320,
                more: '',
            },
        ]
    },{
        id: 4,
        code: '444',
        image: Char4,
        description: 'character_4_description',
        other: [
            {
                id: 401,
                code: '44014',
                image: Char41,
                more: '',
            },{
                id: 402,
                code: '44024',
                image: Char42,
                more: '',
            },{
                id: 403,
                code: '44034',
                image: Char43,
                more: '',
            },{
                id: 404,
                code: '44044',
                image: Char44,
                more: '',
            },{
                id: 405,
                code: '44054',
                image: Char45,
                more: '',
            },{
                id: 406,
                code: '44064',
                image: Char46,
                more: '',
            },{
                id: 407,
                code: '44074',
                image: Char47,
                more: '',
            },{
                id: 408,
                code: '44084',
                image: Char48,
                more: '',
            },{
                id: 409,
                code: '44094',
                image: Char49,
                more: '',
            },{
                id: 410,
                code: '44104',
                image: Char410,
                more: '',
            },{
                id: 411,
                code: '44114',
                image: Char411,
                more: '',
            },{
                id: 412,
                code: '44124',
                image: Char412,
                more: '',
            },{
                id: 413,
                code: '44134',
                image: Char413,
                more: '',
            },
        ]
    },
]