import * as icons from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseDialog } from '../../Redux/slices/settingsSlice';
export default function Dialog() {
    const dispatch = useDispatch();
    const { open, title, image } = useSelector(state => { return state.setting.dialog });
    const closeDialog = (e) => {
        e.preventDefault();
        dispatch(setCloseDialog());
    }
    return (
        <>
            {
                (open && title !== '') &&
                <div key={`popup-${Date.now()}`} className="popup-overlay" onClick={closeDialog}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className='dialog-head'>
                            <p className='title'>
                                {`[Code: ${title}]`}
                            </p>
                            <div className='close-btn' onClick={closeDialog}>
                                <icons.XLg/>
                            </div>
                        </div>
                        <div className='dialog-body'>
                            <div className='image'>
                                <img src={image} alt={title}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};